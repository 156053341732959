import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7e9ccabd&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7e9ccabd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9ccabd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zq/Documents/Projects/2021/sxGame/sxGameWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e9ccabd')) {
      api.createRecord('7e9ccabd', component.options)
    } else {
      api.reload('7e9ccabd', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=7e9ccabd&scoped=true&", function () {
      api.rerender('7e9ccabd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/index/components/d2-badge/index.vue"
export default component.exports