import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/zq/Documents/Projects/2021/sxGame/sxGameWeb/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var userDB = [{
  username: 'admin',
  password: 'admin',
  uuid: 'admin-uuid',
  name: 'Admin'
}, {
  username: 'editor',
  password: 'editor',
  uuid: 'editor-uuid',
  name: 'Editor'
}, {
  username: 'user1',
  password: 'user1',
  uuid: 'user1-uuid',
  name: 'User1'
}];
export default [{
  path: '/api/login',
  method: 'post',
  handle: function handle(_ref) {
    var body = _ref.body;
    var user = userDB.find(function (e) {
      return e.username === body.username && e.password === body.password;
    });

    if (user) {
      return {
        code: 0,
        msg: '登录成功',
        data: _objectSpread({}, user, {
          token: '8dfhassad0asdjwoeiruty'
        })
      };
    } else {
      return {
        code: 401,
        msg: '用户名或密码错误',
        data: {}
      };
    }
  }
}];